<template>
  <v-dialog
    v-model="dialog"
    @click:outside="close()"
    scrollable
    :persistent="loading || !!ticket"
    max-width="400px"
    :fullscreen="!!ticket"
    content-class="modal-bg-dark"
  >
    <v-card :loading="loading" :style="modalBg" dark>
      <div class="mt-2" />

      <v-card-text class="mt-4 pb-1" style="overflow: hidden">
        <div class="d-flex flex-column h-full justify-center">
          <template v-if="!!ticket && !success">
            <div class="d-flex flex-column align-center gap-4 text-center">
              <base-avatar
                v-if="ticket.Owner.photo"
                :seed="ticket.Owner.id"
                :size="150"
                :src="ticket.Owner.photo"
                class="mb-1"
              />
              <v-icon v-else size="120">mdi-account</v-icon>
              <v-chip v-if="membership">
                {{ membership.name }}
              </v-chip>
              <div>
                <h6 class="text-overline font-weight-black lh-1 mb-1">
                  {{ ticket.TicketBlock.TicketGroup.name }}
                </h6>
                <h6 class="text-overline font-weight-bold lh-1 mb-0">
                  {{ ticket.TicketBlock.name }}
                </h6>
              </div>
              <div v-if="ticket.Table">
                <h6 class="text-overline font-weight-black lh-1 mb-1">
                  {{ ticket.Table.Group.name }} • {{ ticket.Table.name }}
                </h6>
              </div>
              <div>
                <h4 class="mb-0">{{ ticket.Owner.name }}</h4>
                <h5>{{ ticket.Owner.document }}</h5>
              </div>

              <!-- Last Ticket Entry -->
              <v-alert
                dense
                v-if="ticket.TicketEntry.length"
                type="warning"
                text
              >
                Tentativa de entrada recusada
                <b class="">
                  {{ [ticket.TicketEntry[0].createdAt] | dateDiff(true) }}
                  {{
                    ticket.TicketEntry[0].RegisterBy
                      ? "por " + ticket.TicketEntry[0].RegisterBy.name
                      : ""
                  }}
                  {{
                    ticket.TicketEntry[0].Session
                      ? "em " + ticket.TicketEntry[0].Session.name
                      : ""
                  }}
                </b>
              </v-alert>
            </div>

            <!-- Details -->
            <div v-if="!details">
              <v-btn @click="details = true" text small block>
                <v-icon>mdi-chevron-down</v-icon>
                Detalhes
              </v-btn>
            </div>
            <template v-else>
              <v-divider class="mb-4" />
              <p class="mb-0 text-center">
                <b>Vendedor:</b> {{ ticket.Seller?.name || "Loja Online" }}
              </p>
              <p class="mb-0 text-center">
                <b>Meio de Pagamento:</b>
                {{ paymentMethods[ticket.Payment.paymentMethod].text }}
              </p>
              <p class="mb-0 text-center">
                <b>Forma de Pagamento:</b>
                {{ paymentTypes[ticket.Payment.paymentType].text }}
              </p>
            </template>
          </template>

          <!-- Success -->
          <template v-else-if="!!ticket">
            <div
              class="d-flex align-center justify-center gap-x-4 gap-y-2 text-center"
              :class="{ 'flex-column': !tableMap }"
            >
              <v-icon class="success--text" size="75">mdi-ticket</v-icon>
              <div>
                <h4 class="success--text mb-0 font-weight-black text-center">
                  Entrada permitida
                </h4>
                <p class="text-center mb-0">
                  em {{ success.createdAt | date("DD/MM/YY HH:mm") }}
                </p>
              </div>
            </div>
            <div class="d-flex align-center justify-center">
              <v-card
                v-if="tableMap"
                outlined
                class="flex-grow-1 mb-4 rounded-lg"
                style="max-width: 500px"
              >
                <table-selector
                  :tableMap="tableMap"
                  :party="party"
                  :tooltip="formatTooltip"
                  fixedTooltip
                />
              </v-card>
            </div>
          </template>
          <div v-if="error">
            <v-alert type="error">
              {{ error.message }}
            </v-alert>
            <v-alert
              color="info"
              dense
              text
              class="text-center"
              v-if="error.period"
            >
              Período:
              <b>
                {{ error.period.name || `Periodo ${error.period.index + 1}` }}
              </b>
            </v-alert>
            <h6 class="text-center" v-if="error.entry?.RegisterBy">
              Registado por: {{ error.entry.RegisterBy.name }} as
              {{ error.entry.createdAt | date("DD/MM/YY HH:mm") }}
            </h6>
            <h6 class="text-center" v-if="error.entry?.Session">
              Registado em {{ error.entry.Session.name }} as
              {{ error.entry.createdAt | date("DD/MM/YY HH:mm") }}
            </h6>
          </div>
        </div>
      </v-card-text>

      <v-card-actions v-if="error || success">
        <div class="w-full">
          <v-progress-linear
            v-if="timeoutDelay"
            color="success"
            height="30px"
            class="rounded"
            :value="timeoutDelay / 30"
          >
            Fechando em
            {{ parseInt((timeoutDelay + 300) / 1000) }}s
          </v-progress-linear>
          <v-btn block text x-large @click="close(true)">Fechar</v-btn>
        </div>
      </v-card-actions>
      <v-card-actions v-if="ticket && !success">
        <v-row align="center" dense class="mx-0">
          <v-col cols="12" v-if="period">
            <v-alert type="info" text class="mb-2">
              Validando para o período
              <b>{{ period.name || period.index + 1 }}</b>
            </v-alert>
          </v-col>
          <v-col cols="auto" class="mb-2">
            <v-btn
              x-large
              icon
              block
              @click="close(true)"
              :disabled="loadingConfirm"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="" class="mb-2">
            <v-btn
              color="error"
              block
              @click="reject"
              :disabled="loadingConfirm"
              x-large
            >
              Recusar
            </v-btn>
          </v-col>
          <v-col cols="" class="mb-2">
            <v-btn
              block
              color="primary"
              @click="confirm"
              :loading="loadingConfirm"
              x-large
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import { mapGetters } from "vuex";
import PAYMENT from "@/utils/payment";
import TableSelector from "@/components/global/partyTable/TableSelector.vue";
export default {
  components: { TableSelector },
  data: () => ({
    dialog: false,
    loading: false,
    loadingConfirm: false,
    error: null,
    errorDetails: null,
    code: null,
    ticket: null,
    period: null,
    membership: null,
    details: false,
    paymentTypes: PAYMENT.paymentType,
    paymentMethods: PAYMENT.paymentMethod,
    success: false,
    timeout: null,
    timeoutDelay: null,
    tableMap: null,
  }),
  methods: {
    vibrate(val) {
      if (navigator.vibrate) navigator.vibrate(val);
    },
    reject() {
      try {
        this.save(false);
        this.close(true);
      } catch (e) {
        console.error(e);
      }
    },

    async confirm() {
      try {
        this.loadingConfirm = true;
        const response = await this.save(true);
        this.success = response.entry;

        if (!this.ticket.Table) this.closeTimeout(1000);
        this.vibrate(400);
      } catch (e) {
        this.error = { message: e.message || "Erro ao confirmar entrada" };
        this.vibrate([200, 200]);
      } finally {
        this.loadingConfirm = false;
      }
    },

    async save(approved) {
      this.error = null;
      return await ORGANIZATION.party.entrance.ticket.register(
        this.selectedOrganization.id,
        this.$route.params.partyId,
        this.code,
        approved
      );
    },

    closeTimeout(timeout) {
      if (this.timeout) clearTimeout(this.timeout);
      if (this.timeoutDelay === null) this.timeoutDelay = timeout || 5000;
      else if (this.timeoutDelay <= 0) {
        clearTimeout(this.timeout);
        return this.close(true);
      }
      const tick = 100;
      this.timeout = setTimeout(() => {
        this.timeoutDelay -= tick;
        this.closeTimeout();
      }, tick);
    },

    async getTicketDetails() {
      try {
        this.vibrate(100);
        this.loading = true;
        this.error = null;
        const { ticket, period, membership } =
          await ORGANIZATION.party.entrance.ticket.getDetails(
            this.selectedOrganization.id,
            this.$route.params.partyId,
            this.code
          );
        this.ticket = ticket;
        this.period = period;
        this.membership = membership;
        this.$nextTick(() => {
          this.formattedTable();
        });
      } catch (error) {
        this.error = {
          message: error.message || "Ocorreu um erro inesperado",
          entry: error.error?.entry,
          period: error.error?.period,
        };
        this.vibrate([200, 200]);
      } finally {
        this.loading = false;
      }
    },
    processCode(code) {
      const uuidRegex =
        "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";

      const isUUID = new RegExp(`^${uuidRegex}$`).test(code);
      if (isUUID) {
        this.code = code;
        return this.getTicketDetails();
      }

      const ticketMatch = new RegExp(`t(icket)?\/(${uuidRegex})`).exec(code);
      if (ticketMatch) {
        this.code = ticketMatch[2];
        return this.getTicketDetails();
      }

      const membership = /m(embership)?\/(\d+)/;
      if (membership.test(code)) {
        this.error = {
          message:
            "Ops, esse código é de um cartão de sócio, não de um ingresso",
        };
        return;
      }
    },
    open(code) {
      this.loadingConfirm = false;
      this.details = false;
      this.error = null;
      this.ticket = null;
      this.period = null;
      this.membership = null;
      this.success = false;
      this.tableMap = null;
      this.dialog = true;
      this.processCode(code);
    },
    close(force = false) {
      if (!force && (this.loading || this.loadingConfirm || !!this.ticket))
        return;
      this.dialog = false;
      this.code = null;
      this.ticket = null;
      this.period = null;
      clearTimeout(this.timeout);
      this.timeout = null;
      this.timeoutDelay = null;
    },
    formattedTable() {
      if (!this.ticket || !this.ticket.Table) return null;
      var { Table: tempTable } = this.ticket;
      if (!tempTable) return null;
      var { Group: tempGroup, ...Table } = tempTable;

      var { Map, ...Group } = tempGroup;
      const data = {
        ...Map,
        Groups: [
          {
            ...Group,
            Tables: [
              {
                ...Table,
              },
            ],
          },
        ],
      };

      this.tableMap = data;
    },
    formatTooltip(table, group) {
      return `<p class="mb-0 text-center">Mesa do Cliente<br><b class="font-weight-bold">${group.name} • ${table.name}</b></p>`;
    },
  },
  watch: {
    dialog(val) {
      if (!val) this.$emit("close");
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    modalBg() {
      if (!this.ticket || this.loading || !this.membership) return null;
      return {
        background: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) 80%, rgba(0, 0, 0, 0.6) 100%), url(${
          this.membership.backgroundImg
        }), ${this.membership.backgroundColor || "#3333"}80`,
        backgroundSize: "cover",
      };
    },
  },
  mounted() {
    this.$parent.$on("entry-confirm", this.open);
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
.modal-bg-dark {
  background-color: #1e1e1e !important;
}
</style>
